<template>
  <a-select
    field="state"
    :label="null"
    :manual-doc="manualDoc"
    :options="options"
  />
</template>

<script>
export default {
  props: {
    row: {
      type: Object
    }
  },
  computed: {
    manualDoc() {
      return {
        module: 'failureParts',
        id: this.row.id
      }
    },
    options() {
      return this.$store.getters["failureParts/fields"].state.options
    }
  }
}
</script>
